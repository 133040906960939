import JSZip from 'jszip'
import FileSaver from 'file-saver'
import axios from 'axios'
import { Loading } from 'element-ui'

const getFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    })
      .then((data) => {
        resolve(data.data)
      })
      .catch((error) => {
        reject(error.toString())
      })
  })
}
// 批量下载
export const batchDownload = async (selectImgList, filesName) => {
  const loading = Loading.service({
    lock: true,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    text: '正在下载文件...'
  })

  const zip = new JSZip()
  const cache = {}
  const promises = []
  await selectImgList.forEach((item) => {
    const promise = getFile(item).then((data) => {
      // 下载文件, 并存成ArrayBuffer对象
      const arrName = item.split('/')
      const fileName = arrName[arrName.length - 1] // 获取文件名
      zip.file(fileName, data, {
        binary: true
      }) // 逐个添加文件
      cache[fileName] = data

      loading.text = `下载进度${Object.keys(cache).length}/${selectImgList.length}`
    })
    promises.push(promise)
  })
  Promise.all(promises)
    .then(() => {
      loading.text = '压缩中...'
      zip
        .generateAsync({
          type: 'blob'
        })
        .then((content) => {
          // 生成二进制流
          loading.close()
          FileSaver.saveAs(content, `${filesName}.zip`) // 利用file-saver保存文件
        })
        .catch(() => {
          loading.text = '压缩失败'

          setTimeout(() => {
            loading.close()
          }, 400)
        })
    })
    .catch(() => {
      loading.text = '下载失败'

      setTimeout(() => {
        loading.close()
      }, 400)
    })
}

// 单图下载
export const downloadImage = (url, filename) => {
  // 创建一个隐藏的 <a> 元素
  const link = document.createElement('a')
  link.style.display = 'none'

  // 设置图片的 URL 和下载的文件名
  link.href = url
  link.download = filename

  // 将 <a> 元素添加到页面中
  document.body.appendChild(link)

  // 触发点击事件进行下载
  link.click()

  // 清理并移除 <a> 元素
  document.body.removeChild(link)
}

export const downloadPDF = (url, name) => {
  axios({
    method: 'get',
    url,
    responseType: 'blob'
  }).then((response) => {
    const link = document.createElement('a')
    const blob = new Blob([response.data], { type: response.data.type })
    const blobUrl = URL.createObjectURL(blob)
    link.href = blobUrl
    link.download = name
    link.click()
  })
}
